var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingProfile
    ? _c(
        "div",
        {
          staticClass:
            "flex flex-col items-center justify-center h-screen mx-auto bg-gray-200 overflow-hidden",
          attrs: { id: "login" },
        },
        [
          _c(
            "div",
            { staticClass: "mb-2 logo-login" },
            [
              _vm.customDomain
                ? _c("custom-brand-img", {
                    attrs: {
                      type: _vm.customImgTypes.BG_LIGHT,
                      "img-class": "custom-brand",
                    },
                  })
                : _c("sun-media-logo-dark", { staticClass: "p-8" }),
            ],
            1
          ),
          _c("div", { staticClass: "w-auto max-w-full sm:max-w-4xl" }, [
            _c(
              "div",
              { staticClass: "w-full lg:w-1/2" },
              [
                _vm.errorPassEmail
                  ? _c("asterix-error-alert", {
                      staticClass: "alert-margin-right",
                      attrs: {
                        "text-title": "Error",
                        "text-description": "Wrong email or password.",
                      },
                    })
                  : _vm._e(),
                _vm.showSuccess
                  ? _c(
                      "sun-alert",
                      {
                        staticClass: "alert-margin-right",
                        attrs: { variant: "success" },
                        on: {
                          click: function ($event) {
                            _vm.showSuccess = false
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(" Success !! ")]
                        ),
                        _c(
                          "span",
                          {
                            attrs: { slot: "description" },
                            slot: "description",
                          },
                          [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-row justify-content-between bg-white shadow-lg rounded-lg text-left max-w-full",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex w-full lg:w-1/2 justify-center inner-login max-w-full pb-10 pt-8 px-4",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "shadow-lg rounded-lg border border-gray-200 pt-2 pb-6 pr-10 pl-8",
                      },
                      [
                        _c("sun-form", { on: { submit: _vm.login } }, [
                          _c(
                            "div",
                            { staticClass: "flex flex-start w-full mb-4 my-2" },
                            [
                              _c(
                                "sun-label-group",
                                {
                                  staticClass: "text-xs w-full",
                                  attrs: {
                                    id: "email",
                                    text: "Email",
                                    "is-required": false,
                                  },
                                },
                                [
                                  _c("asterix-input", {
                                    attrs: {
                                      id: "email",
                                      type: "email",
                                      name: "username",
                                      "validate-on-blur": "",
                                      placeholder: "Email",
                                      required: "required",
                                    },
                                    model: {
                                      value: _vm.form.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "username", $$v)
                                      },
                                      expression: "form.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex items-start w-full" },
                            [
                              _c(
                                "sun-label-group",
                                {
                                  staticClass: "text-xs w-full",
                                  attrs: {
                                    id: "password",
                                    text: "Password",
                                    "is-required": false,
                                  },
                                },
                                [
                                  _c("asterix-input", {
                                    attrs: {
                                      id: "password",
                                      type: "password",
                                      name: "password",
                                      "min-strength": 0,
                                      placeholder: "****************",
                                      required: "required",
                                    },
                                    model: {
                                      value: _vm.form.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "password", $$v)
                                      },
                                      expression: "form.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "flex items-center mt-6" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "sun-button",
                                  {
                                    staticClass: "text-sm text-gray-100",
                                    attrs: {
                                      id: "button_submit",
                                      "data-testid": "button-login",
                                      type: "submit",
                                      loading: _vm.isLoading,
                                      variant: "pill",
                                    },
                                  },
                                  [_vm._v(" Sign in ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "text-sm font-medium text-yellow-500 hover:text-yellow-700 pl-8",
                                    attrs: {
                                      id: "forgot_password",
                                      to: { name: "reset-password" },
                                    },
                                  },
                                  [_vm._v(" Forgot password? ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._m(0),
              ]
            ),
            _c("p", { staticClass: "text-center text-gray-500 text-xs mt-6" }, [
              _vm._v("©" + _vm._s(_vm.year) + " SunMedia All rights reserved."),
            ]),
          ]),
        ]
      )
    : _vm.loadingProfile
    ? _c(
        "div",
        { staticClass: "h-screen flex items-center justify-center" },
        [_c("loading")],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "hidden sm:flex w-1/2 inner-login bg-gray-custom rounded-r-lg ml-4",
      },
      [
        _c("img", {
          staticClass: "h-full relative img-login",
          attrs: {
            src: require("../../../assets/svg/imagen-login.svg"),
            width: "110%",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }