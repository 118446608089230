<template>
  <div
    v-if="!loadingProfile"
    id="login"
    class="flex flex-col items-center justify-center h-screen mx-auto bg-gray-200 overflow-hidden"
  >
    <div class="mb-2 logo-login">
      <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_LIGHT" img-class="custom-brand" />
      <sun-media-logo-dark v-else class="p-8"></sun-media-logo-dark>
    </div>
    <div class="w-auto max-w-full sm:max-w-4xl">
      <div class="w-full lg:w-1/2">
        <asterix-error-alert
          v-if="errorPassEmail"
          class="alert-margin-right"
          text-title="Error"
          text-description="Wrong email or password."
        />

        <!-- Alert Success-->
        <sun-alert v-if="showSuccess" variant="success" class="alert-margin-right" @click="showSuccess = false">
          <span slot="title"> Success !! </span>
          <span slot="description">
            {{ successMessage }}
          </span>
        </sun-alert>
      </div>
      <!-- Form -->
      <div class="flex flex-row justify-content-between bg-white shadow-lg rounded-lg text-left max-w-full">
        <div class="flex w-full lg:w-1/2 justify-center inner-login max-w-full pb-10 pt-8 px-4">
          <div class="shadow-lg rounded-lg border border-gray-200 pt-2 pb-6 pr-10 pl-8">
            <sun-form @submit="login">
              <div class="flex flex-start w-full mb-4 my-2">
                <sun-label-group id="email" text="Email" class="text-xs w-full" :is-required="false">
                  <asterix-input
                    id="email"
                    v-model="form.username"
                    type="email"
                    name="username"
                    validate-on-blur
                    placeholder="Email"
                    required="required"
                  />
                </sun-label-group>
              </div>
              <div class="flex items-start w-full">
                <sun-label-group id="password" text="Password" class="text-xs w-full" :is-required="false">
                  <asterix-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    name="password"
                    :min-strength="0"
                    placeholder="****************"
                    required="required"
                  />
                </sun-label-group>
              </div>
              <div class="flex items-center mt-6">
                <div>
                  <sun-button
                    id="button_submit"
                    data-testid="button-login"
                    type="submit"
                    :loading="isLoading"
                    variant="pill"
                    class="text-sm text-gray-100"
                  >
                    Sign in
                  </sun-button>
                </div>
                <div class="text-right">
                  <router-link
                    id="forgot_password"
                    :to="{ name: 'reset-password' }"
                    class="text-sm font-medium text-yellow-500 hover:text-yellow-700 pl-8"
                  >
                    Forgot password?
                  </router-link>
                </div>
              </div>
            </sun-form>
          </div>
        </div>
        <div class="hidden sm:flex w-1/2 inner-login bg-gray-custom rounded-r-lg ml-4">
          <img src="../../../assets/svg/imagen-login.svg" width="110%" class="h-full relative img-login" />
        </div>
      </div>
      <p class="text-center text-gray-500 text-xs mt-6">&copy;{{ year }} SunMedia All rights reserved.</p>
    </div>
  </div>
  <div v-else-if="loadingProfile" class="h-screen flex items-center justify-center">
    <loading></loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGIN, LAST_URL, USER } from '@/store/modules/auth/keys';
import SunMediaLogoDark from '@/components/icons/SunMediaLogoDark';
import { YEAR } from '@/utils/dateTime/year';
import Loading from '../../../components/atoms/LogoLoading';
import { ACTIVE_CLIENT, SET_ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';
import logger from '@/model/shared/Logger';
import { isAdminUser } from '@/utils/user/isAdminUser';
import AsterixErrorAlert from '@/components/molecules/shared/AsterixErrorAlert';
import AsterixInput from '@/components/atoms/AsterixInput';
import redirectToContextPage from '@/router/utils/redirectToContextPage';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';

export default {
  name: 'Login',
  components: {
    SunMediaLogoDark,
    Loading,
    AsterixErrorAlert,
    AsterixInput,
    CustomBrandImg,
  },
  props: {
    showSuccessAlert: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      loadingProfile: false,
      form: {
        username: null,
        password: null,
      },
      errorsText: {
        username: 'This field is required',
        password: 'This field is required',
      },
      showSuccess: this.showSuccessAlert,
      errorPassEmail: false,
      year: YEAR,
      customDomain: isCustomDomain(),
      customImgTypes,
    };
  },
  computed: {
    ...mapGetters({
      lastUrl: LAST_URL,
      activeClient: ACTIVE_CLIENT,
      user: USER,
    }),
  },
  methods: {
    ...mapActions({
      authLogin: AUTH_LOGIN,
      getProfile: USER,
      setActiveClient: SET_ACTIVE_CLIENT,
    }),
    async login({ form, valid }) {
      if (valid) {
        const username = form.username.value;
        const password = form.password.value;
        this.isLoading = true;
        try {
          await this.authLogin({ username, password });
          this.loadingProfile = true;
          await this.getProfile(true);

          this.redirectToContext();
        } catch (error) {
          this.showSuccess = false;
          this.isLoading = false;
          this.errorPassEmail = true;
          this.loadingProfile = false;
        }
      }
    },
    /**
     * Redirects user to ContextBoard. When the user is not admin is redirected to the defaultView to one of its
     * clients. This causes the router throw an error that is catch to be handled
     *
     * reference: https://stackoverflow.com/q/62223195
     */
    redirectToContext() {
      let direction = this.lastUrl ? { path: this.lastUrl } : null;

      if (!direction) {
        if (isAdminUser(this.user)) {
          direction = { name: `${CONTEXT_BOARD_BASE}-workspaces` };
        } else {
          const route = redirectToContextPage(this.user);
          if (!route) return;
          direction = route;
        }
      }

      this.$router.push(direction).catch(error => {
        logger.warning(error);
      });
    },
  },
};
</script>
<style scoped>
.custom-brand {
  max-height: 100px;
}
@media (min-width: 640px) {
  .inner-login {
    min-width: 370px;
  }

  .img-login {
    right: 10%;
    min-width: 110%;
  }

  .bg-gray-custom {
    background-color: #dbd7d8;
  }
}
@media (min-width: 1024px) {
  .alert-margin-right {
    margin-right: -0.5rem;
  }
}
</style>
